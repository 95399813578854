import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { WareHouseModule } from './material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule } from 'saturn-datepicker'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from "@mat-datetimepicker/core";
import { AgmCoreModule } from '@agm/core';
// import { DashBoardModule } from '../app/dash-board/dash-board.module'
// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
// import { ServiceComponent } from './service/service.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
// import { WorksAboutComponent } from './works-about/works-about.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { GalleryComponent } from './gallery/gallery.component';
// import { UserRegisterComponent } from './user-register/user-register.component';
// import { FilterPipe } from './filter.pipe';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TokenInterceptorService } from './token-interceptor.service';
import { DatePipe } from '@angular/common';
import { from } from 'rxjs';
// import { HeaderComponent } from './header/header.component';
// import { SlaComponent } from './sla/sla.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavBarAllComponent } from './nav-bar-all/nav-bar-all.component';
import { FooterComponent } from './footer/footer.component';
import { OndStorageComponent } from './ond-storage/ond-storage.component';
import { OndServiceComponent } from './ond-service/ond-service.component';
import { BookWithUsComponent } from './book-with-us/book-with-us.component';
import { OmniChannelComponent } from './omni-channel/omni-channel.component';
import { OmniServiceComponent } from './omni-service/omni-service.component';
import { OmniBookWithUsComponent } from './omni-book-with-us/omni-book-with-us.component';
import { PartnerWithUsComponent } from './partner-with-us/partner-with-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BlogsComponent } from './blogs/blogs.component';
import { PricingComponent } from './pricing/pricing.component';
import { RequestQuoteComponent } from './request-quote/request-quote.component';
import { FaqComponent } from './faq/faq.component';
import { HowItsWorksComponent } from './how-its-works/how-its-works.component';
import { PartnerFullPageComponent } from './partner-full-page/partner-full-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';

import { SwiperModule, SwiperConfigInterface,SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { NavbarnewComponent } from './navbarnew/navbarnew.component';
import { SigninnewComponent } from './signinnew/signinnew.component';
import { FootermainComponent } from './footermain/footermain.component';
 
  const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    // ServiceComponent,
    ContactUsComponent,
    // WorksAboutComponent,
    GalleryComponent,
    // UserRegisterComponent,
    // FilterPipe,
    // HeaderComponent,
    // SlaComponent,
    NavBarComponent,
    NavBarAllComponent,
    FooterComponent,
    OndStorageComponent,
    OndServiceComponent,
    BookWithUsComponent,
    OmniChannelComponent,
    OmniServiceComponent,
    OmniBookWithUsComponent,
    PartnerWithUsComponent,
    AboutUsComponent,
    BlogsComponent,
    PricingComponent,
    RequestQuoteComponent,
    FaqComponent,
    HowItsWorksComponent,
    PartnerFullPageComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    NavbarnewComponent,
    SigninnewComponent,
    FootermainComponent,
   
      
     
   
  ],
  imports: [
    BrowserModule,
    SwiperModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // DashBoardModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    ScrollToModule.forRoot(),
    WareHouseModule,
    HttpClientModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    SatDatepickerModule,
    // NgxMaterialTimepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: 'toast-center-center',
      preventDuplicates: true
    }),
    MatFormFieldModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCuc_qvc4eqTFp2iu04STZOPUAHcTsMVsw',
      //created by Vignesh on 25th Mar
      libraries: ['places']
    }),
  ],
  providers:
    [
      DatePipe,

      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptorService,
        multi: true,
      },
      {
        provide: SWIPER_CONFIG,
        useValue: DEFAULT_SWIPER_CONFIG
      },
      DatePipe,
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
  bootstrap: [AppComponent],
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);