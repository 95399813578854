import { Component, ElementRef, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from '../api.service';
import { MustMatch } from '../dash-board/changepassword/passwordvalidator';
import lodash from 'lodash';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar-all',
  templateUrl: './nav-bar-all.component.html',
  styleUrls: ['./nav-bar-all.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class NavBarAllComponent implements OnInit {
  logoActive : boolean;
  BllogoActive : boolean;
  hide = true;
  signInView = true;
  profileView = false;
  otpGen = false;
  setPswrd = false;
  forgotPswrd = true
  submitted = false;
  dashboardRouting = true;
  parentmsg : any;
  storeTokeValue: any;

  signInFormGroup: FormGroup;
  forgotFormGroup: FormGroup;
  VerifyOtpFormGroup: FormGroup;
  New_pswsrd_Form_Grp: FormGroup

  loginParameter = { mobileNo: '', password: '', userGroup: 'customer', client:"web"   }
  // loginParameter = { mobileNo: '', password: '', userTypeId: "4" }
  RequiredOtpGenerate = { mobileNo: "" }
  ReuiredOtpVerify = { mobileNo: this.RequiredOtpGenerate.mobileNo, otp: ""}
  RequiredNewPassword = { updates: { email: "", fullname:"", password: "", mobileNo:"" } }

  helper = new JwtHelperService();
  decodedToken: any;

  constructor(private elementRef: ElementRef, private dialog: MatDialog, private formBuilder: FormBuilder, private apiAuth: ApiService, private router: Router) { }
  ngOnInit(): void {
    this.logoActive = true;
    window.scrollTo({top: 0, behavior: 'smooth'});

    this.storageLoginForm()
    this.forgotForm();
    this.verifyOtpform();
    this.setNewPassword()

    this.parentmsg = {
      profileIcon: this.profileView,
      logInBt: this.signInView,
    }
    if (localStorage.getItem('session_access_token') === null || localStorage.getItem('session_access_token') === undefined) {
      // //console.log("PROFILE VIEW CLOSED")
    }
    else {
      this.profileView = true;
      this.signInView = false;
      this.parentmsg = {
        SignInValues: this.loginParameter,
        tokenValues: this.storeTokeValue, profile: this.profileView, signInBt: this.signInView
      };
    }



    // document.addEventListener('scroll', handleOrientation)
    
    // function handleOrientation(event) {
    //   let header = document.querySelector('header');
    //   if(header){
    //     let windowPosition = window.scrollY > 0;
    //     header.classList.toggle('header', windowPosition); 
    //   }
    // }

  }

  // ngDoCheck(){
  //   if(window.scrollY > 0) {
  //     this.logoActive = false;
  //     this.BllogoActive = true;
  //   }else{
  //     this.logoActive = true;
  //     this.BllogoActive = false;
  //   }
  // }

  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef,{ panelClass: 'custom-modalbox'});
    // this.dialogRef.close(true);
  }

  openPartner(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef,{ panelClass: 'custom-modalbox'});
    // this.dialogRef.close(true);
  }

  storageLoginForm() {
    this.signInFormGroup = new FormGroup({
      mobileNo: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }
  public checkSignInError = (controlName: string, errorName: string) => {
    return this.signInFormGroup.controls[controlName].hasError(errorName);
  }

  _keyPress(event: any) {
    const pattern = /[0-9, ,+]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  forgotForm() {
    this.forgotFormGroup = new FormGroup({
      mobileNo: new FormControl('', [Validators.required, Validators.min(10)])
    })
  }
  public checkForgotError = (controlName: string, errorName: string) => {
    return this.forgotFormGroup.controls[controlName].hasError(errorName);
  }

  verifyOtpform() {
    this.VerifyOtpFormGroup = new FormGroup({
      otp: new FormControl('', [Validators.required])
    })
  }
  public checkVerifyOtpError = (controlName: string, errorName: string) => {
    return this.VerifyOtpFormGroup.controls[controlName].hasError(errorName);
  }

  setNewPassword() {
    this.New_pswsrd_Form_Grp = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmpassword')
    });
  }
  get f() {
    return this.New_pswsrd_Form_Grp.controls;
  }



  /*----------------------------------API CALLING FOR SIGN_IN-------------------------------------*/

   login(loginToken) {
    this.apiAuth.getLoginApi(this.loginParameter)
      .subscribe(res => {
        if (res.status == true) {
          localStorage.setItem('mobile_no', this.loginParameter.mobileNo)
          localStorage.setItem('pwd', this.loginParameter.password)
          localStorage.setItem('session_access_token', res.token);
          localStorage.setItem('Refresh_token', res.refreshToken);
          // localStorage.setItem('Cus_MbNo', '')
          this.profileView = true
          this.storeTokeValue = res.token
          this.signInView = false;
          this.parentmsg = {
            SignInValues: this.loginParameter,
            tokenValues: this.storeTokeValue, profile: this.profileView, signInBt: this.signInView
          };
          loginToken = this.parentmsg;
          this.decodedToken = this.helper.decodeToken(localStorage.getItem('session_access_token'));
          localStorage.setItem('CusID', this.decodedToken.customerId)
          localStorage.setItem('ID', this.decodedToken.id)
          localStorage.setItem('U_name', this.decodedToken.name)
          localStorage.setItem('U_email', this.decodedToken.email)
          console.log(this.decodedToken)
          // this.dataTranService.login(loginToken)
          Swal.fire({
            toast: true,
            position: 'center',
            text: 'Login Successfully',
            showConfirmButton: false,
            timer: 3000,
            type: 'success',
          })
        }
        else {
          Swal.fire({
            toast: true,
            position: 'center',
            text: 'Login Failed Please Check Username and Password',
            showConfirmButton: false,
            timer: 3000,
            type: 'error',
          })
        }
      })
  }


/*---------------------------------API CALLING FOR FORGOT_PASSWORD-----------------------------------*/

forgotPassword() {
  this.apiAuth.GetOtpGenerate(this.RequiredOtpGenerate)
    .subscribe(
      res => {
        if(res.error === "There is no user with the provided information."){
          Swal.fire({
            toast: true,
            title: "There is no user with the provided information",
            type: 'error',
            timer: 2000,
            showConfirmButton: false
          })
          this.otpGen = false;
          this.forgotPswrd = true
        }else{
          Swal.fire({
            toast: true,
            title: "OTP sent successfully to user Mobile Number",
            type: 'success',
            timer: 2000,
            showConfirmButton: false
          })
        } 
        this.ReuiredOtpVerify.mobileNo = this.RequiredOtpGenerate.mobileNo
      }
    )
  this.otpGen = true;
  this.forgotPswrd = false
}

/*----------------------------API CALLING FOR OTP VERIFY------------------------------*/

otpVerify() {
  this.apiAuth.GetOtpVerify(this.ReuiredOtpVerify)
    .subscribe(
      res => {
        if (this.ReuiredOtpVerify.mobileNo === '' || this.ReuiredOtpVerify.otp === '') {
          Swal.fire({
            toast: true,
            title: "Please Enter Your OTP",
            type: 'warning',
            timer: 2000,
            showConfirmButton: false
          })
        }
        else if (res.status === false) {
          Swal.fire({
            toast: true,
            title: "Please Check Your OTP",
            type: 'warning',
            timer: 2000,
            showConfirmButton: false
          })
        }
        else {
          localStorage.setItem('ID', res.result.userId)
          Swal.fire({
            toast: true,
            title: "OTP Verified",
            type: 'success',
            timer: 2000,
            showConfirmButton: false
          })
          this.setPswrd = true;
          this.otpGen = false;
        }
      }
    )
}

/*----------------------------------API CALLING RESEND OTP---------------------------------------*/
resendOtp() {
  this.apiAuth.GetOtpResend(this.RequiredOtpGenerate)
    .subscribe(res => {
      Swal.fire({
        toast: true,
        title: "OTP resent Successfully",
        type: 'success',
        timer: 2000,
        showConfirmButton: false
      })
    })
}

/*---------------------------------API CALLING FOR RESET PASSWORD----------------------------------*/

changePassword() {
  this.submitted = true;
  if (this.New_pswsrd_Form_Grp.invalid) {
    return;
  }
  const dataToBeSent = this.RequiredNewPassword
  if(lodash.isEqual(dataToBeSent.updates.email,'')) delete dataToBeSent.updates.email
  if(lodash.isEqual(dataToBeSent.updates.mobileNo,'')) delete dataToBeSent.updates.mobileNo
  if(lodash.isEqual(dataToBeSent.updates.fullname,'')) delete dataToBeSent.updates.fullname
  this.apiAuth.getUpdateUser(this.RequiredNewPassword)
    .subscribe(res => {

      localStorage.removeItem('ID')
      Swal.fire({
        toast: true,
        title: "Password Changed Successfully",
        type: 'success',
        timer: 2000,
        showConfirmButton: false
      })
      
    })
}



/*_---------------------------------REDIRECT AND API CALLING FOR LOGOUT---------------------------------*/

dashboardCustomer() {
  if (localStorage.getItem('CusID') === null || localStorage.getItem('CusID') === "" || localStorage.getItem('CusID') === "undefined") {
    Swal.fire({
      title: "<h4 style='color: #27ADE2;'>Please Confirm Your Request</h4>",
      text: 'Are you sure want to logout',
      type: 'info',
    }) .then((willDelete) => {
      if(willDelete.value){
        this.apiAuth.getLogOutApi()
        .subscribe(res => {
          // //console.log("LOGOUT SUCESSFULLY", res)
          if (res.status == true) {
            localStorage.clear();
            Swal.fire({
              toast: true,
              position: 'center',
              text: 'Logout Successfully',
              showConfirmButton: false,
              timer: 2000,
              type: 'success',
            })
          }
        })
      }else{
        this.apiAuth.getLogOutApi()
        .subscribe(res => {
          // //console.log("LOGOUT SUCESSFULLY", res)
          if (res.status == true) {
            localStorage.clear();
            Swal.fire({
              toast: true,
              position: 'center',
              text: 'Logout Successfully',
              showConfirmButton: false,
              timer: 2000,
              type: 'success',
            })
          }
        })
      }
  });
    this.profileView = false;
    this.signInView = true;
  } else {
    this.profileView = true;
    this.dashboardRouting = true;
    this.router.navigate(['/customer/dashboard'])
    // this.signInView = true;
  }
}
}
