import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-ond-storage',
  templateUrl: './ond-storage.component.html',
  styleUrls: ['./ond-storage.component.css']
})
export class OndStorageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toServices(tab: string){
    document.getElementById("ond-service-container").scrollIntoView({behavior:"smooth"});
  }
}
