import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import lodash from 'lodash';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';

interface City {
  value: string;
  viewValue: string;
}


interface Month {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'app-book-with-us',
  templateUrl: './book-with-us.component.html',
  styleUrls: ['./book-with-us.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BookWithUsComponent implements OnInit {

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  cities: City[] = [
    { value: '1', viewValue: 'Bangalore' },
    { value: '2', viewValue: 'Chennai' }
    // { value: 'Hyderabad', viewValue: 'Hyderabad' },
  ]

  serviceType = new FormControl();

  ServicesList = ['Storage', 'Order Management', 'Order Fulfilment', 'Inventory Control', 'API Integration', 'Others'];
  selectedServices;

  Months: Month[] = [
    { value: '15', viewValue: '15 days' },
    { value: '30', viewValue: '1 month' },
    { value: '60', viewValue: '2 months' },
    { value: '90', viewValue: '3 months' },
    { value: '180', viewValue: '6 months' },
    { value: '200', viewValue: '> 6 months' }
  ];

  OndbookingFormGroup: FormGroup;

  OndScreenRequired = {
      // fullname: "",
      // email: "",
      mobileNo: "",
      storageDuration: "",
      possibleStartDate: "",
      cityId: "",
      serviceType:[],
      requestSource: "ond"
  }
  minDate = new Date();
  
  constructor(private formBuilder: FormBuilder, private Apicall: ApiService, private datePipe: DatePipe, @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit() {
    this.createForm()
  }

  createForm() {
    this.OndbookingFormGroup = this.formBuilder.group({
      city: ['', Validators.required],
      storageDuration: ['', Validators.required],
      possibleStartDate: ['', Validators.required],
      mobileNo : ['', Validators.required],
      serviceType: ['', Validators.required],
    });
  }

  public checkError = (controlName: any, errorName: any) => {
    return this.OndbookingFormGroup.controls[controlName].hasError(errorName);
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  SubmitOndDetails(){
    if (this.OndbookingFormGroup.invalid) {
      return;
    }
    this.OndScreenRequired.possibleStartDate = this.datePipe.transform(this.OndScreenRequired.possibleStartDate, 'yyyy-MM-dd')
    console.log(this.OndScreenRequired)
    this.Apicall.getStoreWithUs(this.OndScreenRequired)
      .subscribe(res => {
        if (res.status == true) {

          Swal.fire({
            title: "<h6 style='color: #27ADE2;'>Thank you for posting your requirement. Our Sales team shall get in touch with you soon</h6>",
            showConfirmButton: false,
            timer: 7000,
            imageUrl: 'assets/images/Confirmbooking.png',
          })
          this.formDirective.resetForm();
        }
      })
      err=>{
            console.log("Error Spotted")
      }
  }
}
