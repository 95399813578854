import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router, private sendFile: ApiService) { }

  ngOnInit() {
  }

  toHome(){
    document.getElementById('home').scrollIntoView({behavior:'smooth'})
  }

  toAboutus(){
    document.getElementById('aboutUs').scrollIntoView({behavior:'smooth'})
    // this.router.navigate(['aboutus'])
  }

  toHowitswork(){
    document.getElementById('howitsworks').scrollIntoView({behavior:'smooth'})
    this.router.navigate(['howitsworks'])
  }

  toPricing(){
    document.getElementById('pricing-container').scrollIntoView({behavior:'smooth'})
    this.router.navigate(['pricing'])
  }

  toPartner(){
    document.getElementById('ond-service-container').scrollIntoView({behavior:'smooth'})
    this.router.navigate(['ond'])
  }

  OmniChannel(){
    let omni = document.getElementById('omni_channel').id
     this.sendFile.saveData<string>(omni.toString());
     this.router.navigate(['omnichannel'])
  }

  WarehouseManage() {
    let warehouse = document.getElementById('warehouse_manage').id
     this.sendFile.saveData<string>(warehouse.toString());
     this.router.navigate(['omnichannel'])
   }
  
   DataAnaly(){
    let dataAnal = document.getElementById('data_analy').id
    this.sendFile.saveData<string>(dataAnal.toString());
    this.router.navigate(['omnichannel'])
   }
  
   ApiIntegration(){
    let apiInt = document.getElementById('api_int').id
    this.sendFile.saveData<string>(apiInt.toString());
    this.router.navigate(['omnichannel'])
   }
}
