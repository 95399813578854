import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import lodash from 'lodash';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.css']
})
export class RequestQuoteComponent implements OnInit {

  request_form : FormGroup;
  submitted = false;
  request_quote_params = { name:'', email:'',mobile:'', website:'',monthlyorder:'', companyName:''};

  constructor(private formBuilder: FormBuilder, private router: Router, private APiCall :ApiService) { }

  ngOnInit() {
    this.request_form = this.formBuilder.group({
      name: ['', Validators.required],
      b_name: ['', Validators.required],
      ph_number: ['', Validators.required],
      website: [''],
      email: [''],
      m_order: ['']
    })
  }

  get f() {
    return this.request_form.controls;
  }

  submit_details(){ 
    this.submitted = true;
    if(this.request_form.invalid){
      return
    }
    // const dataToBeSent = this.request_quote_params
    // if(lodash.isEqual(dataToBeSent.name,'')) delete dataToBeSent.name
    // if(lodash.isEqual(dataToBeSent.email,'')) delete dataToBeSent.email
    // if(lodash.isEqual(dataToBeSent.mobile,'')) delete dataToBeSent.mobile
    // if(lodash.isEqual(dataToBeSent.website,'')) delete dataToBeSent.website
    // if(lodash.isEqual(dataToBeSent.monthlyorder,'')) delete dataToBeSent.monthlyorder
    this.APiCall.getRequestquote(this.request_quote_params)
    .subscribe(
      res => {
        if(res.responsetext === 'Invalid Parameters'){
          Swal.fire({
            toast: true,
            title: res.responsetext,
            type: "error",
            showConfirmButton: false,
            timer: 3000
          })
        }else{
          Swal.fire({
            toast: true,
            title: 'Submitted Successfully',
            type: "success",
            showConfirmButton: false,
            timer: 3000
          })
        }

        err => {
          Swal.fire({
            toast: true,
            title: err,
            type: "error",
            showConfirmButton: false,
            timer: 3000
          })
        }
        this.router.navigate([''])
      }
    )
  }

}