import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-ond-service',
  templateUrl: './ond-service.component.html',
  styleUrls: ['./ond-service.component.css']
})
export class OndServiceComponent implements OnInit {
  public OndValue = '';
  mobileMedia: any = window.matchMedia("(max-width:1000px)")
  constructor(private getData: ApiService) { }

  ngOnInit(): void {
    AOS.init({
      duration: 2000,
    })

    this.getData.on<string>().subscribe(
      data => {
        this.OndValue = data
      }
    )

    if (this.mobileMedia.matches) {
      if (this.OndValue == 'minizeValue') {
        window.scrollTo({ top: 700, behavior: 'smooth' });
        window.scrollTo(0, 700)
      }
      else if (this.OndValue == 'minizeValue') {
        window.scrollTo({ top: 700, behavior: 'smooth' });
        window.scrollTo(0, 700)
      }
      else if (this.OndValue == 'DelightValue') {
        window.scrollTo({ top: 1100, behavior: 'smooth' });
        window.scrollTo(0, 1100)
      }
      else if (this.OndValue == 'StockValue') {
        window.scrollTo({ top: 1650, behavior: 'smooth' });
        window.scrollTo(0, 1650)
      }
      else if (this.OndValue == 'PocketValue') {
        window.scrollTo({ top: 2400, behavior: 'smooth' });
        window.scrollTo(0, 2400)
      }
      else if(this.OndValue == 'LeanValue') {
        window.scrollTo({ top: 2900, behavior: 'smooth' });
        window.scrollTo(0, 2900)
      }
    }
    else{
      if (this.OndValue == 'PocketValue') {
        window.scrollTo({ top: 1800, behavior: 'smooth' });
        window.scrollTo(0, 1800)
      }else{
        document.getElementById(this.OndValue).scrollIntoView({ behavior: 'smooth' })
      }
    }
    if (this.OndValue === 'maximizeValue') window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
