import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import lodash from 'lodash';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partner-with-us',
  templateUrl: './partner-with-us.component.html',
  styleUrls: ['./partner-with-us.component.css']
})
export class PartnerWithUsComponent implements OnInit {

  reachout_form: FormGroup;
  submitted = false;
  reachout_params = {name:'',mobile:'',email:'',companyName:'',website:''}

  constructor(private formBuilder: FormBuilder, private router: Router, private APiCall: ApiService) { }

  ngOnInit() {
    this.reachout_form = this.formBuilder.group({
      name: ['', Validators.required],
      b_name: ['', Validators.required],
      ph_number: ['', Validators.required],
      email : [''],
      website: ['']
    })
  }

  get f() {
    return this.reachout_form.controls;
  }

  submit_details() {
    this.submitted = true;
    if (this.reachout_form.invalid) {
      return
    }
    // const dataToBeSent = this.reachout_params
    // if (lodash.isEqual(dataToBeSent.name, '')) delete dataToBeSent.name
    // if (lodash.isEqual(dataToBeSent.email, '')) delete dataToBeSent.email
    // if (lodash.isEqual(dataToBeSent.mobile, '')) delete dataToBeSent.mobile
    // if (lodash.isEqual(dataToBeSent.website, '')) delete dataToBeSent.website
    this.APiCall.getReachoutus(this.reachout_params)
      .subscribe(
        res => {
          if (res.responsetext === 'Invalid Parameters') {
            Swal.fire({
              toast: true,
              title: res.responsetext,
              type: "error",
              showConfirmButton: false,
              timer: 3000
            })
          } else {
            Swal.fire({
              toast: true,
              title: 'Submitted Successfully',
              type: "success",
              showConfirmButton: false,
              timer: 3000
            })
          }

          err => {
            Swal.fire({
              toast: true,
              title: err,
              type: "error",
              showConfirmButton: false,
              timer: 3000
            })
          }
          this.router.navigate([''])
        }
      )
  }

}
