import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-omni-channel',
  templateUrl: './omni-channel.component.html',
  styleUrls: ['./omni-channel.component.css']
})
export class OmniChannelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
