import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-its-works',
  templateUrl: './how-its-works.component.html',
  styleUrls: ['./how-its-works.component.css']
})
export class HowItsWorksComponent implements OnInit {

  constructor() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
   }

  ngOnInit() {
  }

}
