import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GalleryComponent } from './gallery/gallery.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OmniChannelComponent } from './omni-channel/omni-channel.component';
import { OndStorageComponent } from './ond-storage/ond-storage.component';
import { StockStatementComponent } from './dash-board/stock-statement/stock-statement.component';
import { StockLedgerComponent } from './dash-board/stock-ledger/stock-ledger.component';
import { DeliveryOrderComponent } from './dash-board/delivery-order/delivery-order.component';
import { IndividualBookingComponent } from './dash-board/individual-booking/individual-booking.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { ReceiptAlertComponent } from './dash-board/receipt-alert/receipt-alert.component';
import { AddCartComponent } from './dash-board/add-cart/add-cart.component';
import { DispatchReportComponent } from './dash-board/dispatch-report/dispatch-report.component';
import { ViewBookingsComponent } from './dash-board/view-bookings/view-bookings.component';
import { AdditionalSpaceComponent } from './dash-board/additional-space/additional-space.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BlogsComponent } from './blogs/blogs.component';
import { PricingComponent } from './pricing/pricing.component';
import { WorksAboutComponent } from './works-about/works-about.component';
import { HowItsWorksComponent } from './how-its-works/how-its-works.component';
import { PartnerWithUsComponent } from './partner-with-us/partner-with-us.component';
import { PartnerFullPageComponent } from './partner-full-page/partner-full-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { SigninnewComponent } from './signinnew/signinnew.component';
// import { SlaComponent } from './sla/sla.component';
// import { DashBoardComponent } from './dash-board/dash-board.component';

const routes: Routes = [
  {
    path:'signIn',
    component:SigninnewComponent,
    
 
  },
  {
    path:'',
    redirectTo:'signIn',
    pathMatch:'full'
 
  },
  
  {
    path: 'contactus',
    component: ContactUsComponent,
  },
  // {
  //   path: 'faq',
  //   component: FaqComponent,
  // },
  {
    path: 'warehouse',
    component: GalleryComponent,
    // canActivate: [LoginguardGuard]
  },
  {
    path: 'ond',
    component: OndStorageComponent,
    // canActivate: [LoginguardGuard]
    // data: {title: false, depth: 2}
  },
  {
    path: 'omnichannel',
    component: OmniChannelComponent,
    // canActivate: [LoginguardGuard]
    // data: {title: false, depth: 1}
  },
  {
    path:'warehouse',
    component: GalleryComponent,
  },
  {
    path: 'aboutus',
    component: AboutUsComponent
  },
  // {
  //   path: 'blogs',
  //   component: BlogsComponent
  // },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'howitsworks',
    component: HowItsWorksComponent
  },
  {
    path: 'partnerwithus1',
    component: PartnerFullPageComponent
  },
  {
    path:'privacy',
    component:PrivacyPolicyComponent
  },
  {
    path:'termsofservice',
    component:TermsOfServiceComponent
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  // {
  //   path:"sla",
  //   component:SlaComponent,
   
  // },
  {
    path:'',
    // loadChildren:'./dash-board.module#SecondModule'
    // component: DashBoardComponent
    loadChildren: () => import('./dash-board/dash-board.module').then(m => m.DashBoardModule),
  },


  


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
