// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export const myVariables = {
  completedReceiptRowsPerPage: 1,
  completedReceiptPageNumber: 1,

  completedDeliveryRowsPerPage: 1,
  completedDeliveryPageNumber: 1,

  openReceiptRowsPerPage: 1,
  openReceiptPageNumber: 1,

  stagingReceiptRowsPerPage: 1,
  stagingReceiptPageNumber: 1,

  pendingDeliveriespageNumber: 1,
  pendingDeliveriesRowsPerPage: 1,

  pendingReceiptRowsPerPage: 1,
  pendingReceiptPageNumber: 1,

  grnRowsPerPage: 1,
  grnPageNumber: 1,

  returngrnRowsPerPage: 1,
  returngrnPageNumber: 1,

  deliveryNoteRowsPerPage: 1,
  deliveryNotePageNumber: 1,


  allTopRowsPerPage: 1,
  allTopPageNumber: 1,

  allLeastRowsPerPage: 1,
  allLeastPageNumber: 1,
}

export const dashboardVarialbles = {
  HistoryDateRange: 'Last 15 Days',
  SellingDateRange:false,

  receiptHistoryDateRange: 'Last 15 Days',
  deliveryHistoryDateRange: 'Last 15 Days',
  leastSellingDateRange: 'ALL',
  topSellingDateRange: 'ALL',
  mostOrderDateRange: 'ALL',
  stagingVariable: false,
  onlyPendingVariable: false,
  completedVariable: false,
}

export const  SellingDateRange={
  "firstDate": null,
  "lastDate": null
}
