import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GalleryComponent implements OnInit {
  homePage: boolean;
  workPage: boolean;
  servicePage: boolean;
  ContactUsPage: boolean;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.homePage =true;
    this.workPage = true;
    this.servicePage = true;
    this.ContactUsPage = true;
  }
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
}
