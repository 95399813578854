import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError, Observable, of, from, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public liveServer = 'https://business.city-link.co.in/storage';
  // public testServer = 'http://52.170.134.229/storage';
  public testServer = 'http://52.140.121.136/storage';

  public testServer1 = 'http://52.140.121.136/storageledger';

  public testServer2 = 'http://52.140.121.136/storagereports';



  public NewTestServer = 'http://52.170.134.229/storageRbac';

  // public rootPath = this.testServer  
  // public rootPath = this.testServer

  public rootPath = this.liveServer





  public grndetails = this.rootPath + '/transactions/grn/simple'

  public returngrndetails = this.rootPath + '/transactions/grn/returns/simple'

  public deliverynotes = this.rootPath + '/transactions/dn/simple'

  public apitracking = this.rootPath + '/shipment/tracking/order';
  private StoreWithUsUrl = this.rootPath + '/request';
  private contactUrl = this.rootPath + '/booking/contactus';
  private requestquoteUrl = this.rootPath + '/booking/requestquote';
  private reachoutusUtl = this.rootPath + '/booking/reachout';
  private createBookingurl = this.rootPath + '/request/user';
  private LoginUrl = this.rootPath + '/auth/signin';
  private RefreshTokenUrl = this.rootPath + '/auth/validate';
  private LogOutUrl = this.rootPath + '/auth/signout';
  private SignUpUrl = this.rootPath + '/user';
  private updateUrl = this.rootPath + '/user';
  private UnregisterUrl = this.rootPath + '/request';
  private ReceiptUrl = this.rootPath + '/pickupRequest';
  private StockStmtUrl = this.rootPath + '/stock/statement/date';   

  private LedgerDataUrl = this.rootPath + '/search/product/all'; //Here we need to pass userID and then we will get product drop down list
  private LedgerMaterialUrl = this.rootPath + '/stock/ledger'; // Here we should select date range then we will get data
  private AdditionalSpaceUrl = this.rootPath + '/request/additional';
  private DeliveryOrderUrl = this.rootPath + '/end/customer';
  private AutoSuggestUrl = this.rootPath + '/end/customer'
  private supplierAutoSuggestUrl = this.rootPath + '/supplier'


  private DeliverOrderRequestUrl = this.rootPath + '/delivery/request';
  private ViewBookingUrl = this.rootPath + '/booking/bookingbyuser';
  private IndBookingUrl = this.rootPath + '/booking';
  private createCartUrl = this.rootPath + '/cart'
  private cartDetailsUrl = this.rootPath + '/cart/user'
  private viewDashboardUrl = this.rootPath + "/dashboard/warehouse";
  private dashboardByUserUrl = this.rootPath + '/dashboard/user'
  private CartDeleteUrl = this.rootPath + '/cart';
  private DeliveryDetailUrl = this.rootPath + '/delivery/request'
  private ReceiptTodayUrl = this.rootPath + '/pickupRequest';
  private citiesUrl = this.rootPath + '/city';
  private BusinessCategoryUrl = this.rootPath + '/industryCategory';
  private SubCategoryUrl = this.rootPath + '/industrySubcategory';
  private UpdateUserProfileUrl = this.rootPath + '/customer';
  private PendingStatusUrl = this.rootPath + '/pickupRequest/by/customer'; //completed resceipt Table
  private CompletedStatusUrl = this.rootPath + '/delivery/request/by/customer';
  private ReadOnlyPendingUrl = this.rootPath + '/pickupRequest'; // completed Receipt INd
  private SelectMaterialUrl = this.rootPath + '/stock/ledger/by/warehouseAndCustomer';
  private GenerateOTPUrl = this.rootPath + '/otp/generate';
  private ResendOTPUrl = this.rootPath + '/otp/resend';
  private VerifyOTPUrl = this.rootPath + '/otp/verify';
  private UploadDocUrl = this.rootPath + '/files/upload/pickupinvoice';
  private DeliveryUploadUrl = this.rootPath + '/files/upload/deliveryinvoice'
  private PendingDeliveryUrl = this.rootPath + '/delivery/request'
  private CompleteDeliveryUrl = this.rootPath + '/delivery/request'
  private AutoComAddMaterialUrl = this.rootPath + '/material/search/by/customer/sku';
  private SKUMasterUrl = this.rootPath + '/material/sku/master'
  private addMaterialUrl = this.rootPath + '/material/search/by/customer/sku'
  private cancelReceiptUrl = this.rootPath + '/pickupRequest'
  private cancelDeliveryUrl = this.rootPath + '/delivery/request/cancel'
  private warehouseLogin = this.rootPath + '/auth/signin';
  private monthlyReportsUrl = this.rootPath + '/invoice/customer/monthly'
  private dailyReportsUrl = this.rootPath + '/invoice/customer/daily'
  private uploadPoUrl = this.rootPath + '/po/upload'
  private poWithMaterialUrl = this.rootPath + '/po/by/customer'
  private poDeleteUrl = this.rootPath + '/po'
  private poSubMaterialUrl = this.rootPath + '/po/for/pending';
  private SupplierInfoApi = this.rootPath + '/end/customer';
  private ProductInfoApi = this.rootPath + '/search/product';
  
  private ProductInfoApi1 = this.rootPath + '/search/product/in/redis';

  private ProductbasedMaterialApi = this.rootPath + '/stock/statement/materials';
  
  private MaterialMasterSearchApi = this.rootPath + '/material/sku/master';
  private SelectMaterialSearchApi = this.rootPath + '/stock/ledger/by/warehouseAndCustomerAndMaterialcode';
  private CountryCodeUrl = this.rootPath + '/Country';
  private SendingSohUrl = this.rootPath + '/stock/statement/sendemail';
  private viewCustomersUrl = this.rootPath + '/customer/warehouse';   /* Completed */



  private viewSelectedDeliverytUrl = this.rootPath + '/delivery/request';  /* Completed */


  /*----------------------------------dashboard banner new implementation------------------------------*/

  private dashboardBannerUrl = this.rootPath + '/dashboard/user';

  private receiptCountUrl = this.rootPath + '/dashboard/warehouse/receipt/count'
  private receiptHistoryUrl = this.rootPath + '/pickupRequest/status'
  private deliveryHistoryUrl = this.rootPath + '/delivery/request/status'


  private deliveryCountUrl = this.rootPath + '/dashboard/warehouse/deliveries'
  private UnfulfillableordersUrl = this.rootPath + '/dashboard/unfulfillable'
  private goodDamageSkuUrl = this.rootPath + '/product/avilable/quantity'
  private mostOrderPlacedUrl = this.rootPath + '/shipment/most/order'
  private topSellingProductUrl = this.rootPath + '/product/most/sell'
  private leastSellingProductUrl = this.rootPath + '/product/least/sell'
  private lineChartUrl=this.rootPath + '/product/avilable/quantity/warehouseId/11'
 
   /*------------------------deliver request------------------------------*/
  private generateInvoiceUrl = this.rootPath + '/materialstodeliver/ecommerce/generate/invoice';
  private completeMenifestUrl = this.rootPath + '/materialstodeliver/ecommerce/manifest'
  private bulkGenerateInvoiceUrl = this.rootPath + '/materialstodeliver/ecommerce/generate/invoice/bulk'
  private bulkCompleteManifestUrl = this.rootPath + '/materialstodeliver/ecommerce/manifest/bulk'
  private shippingLableUrl = this.rootPath + '/shipment/print/label'
  private manifestLableUrl = this.rootPath + '/shipment/print/manifest'
  private cancelShipmentUrl = this.rootPath + '/shipment/cancel'
  private allocateShipUrl = this.rootPath + '/materialstodeliver/ecommerce/allocate/shipping'


  private createLabelUrl = this.rootPath + '/materialstodeliver/ecommerce/create/invoice'

  /*-------------------------------Create shipment ------------------------------------*/

  // private getRateUrl=this.rootPath + '/shipment/delivery/quote'
  private getRateUrl = this.rootPath + '/shipment/delivery/quote/filter'
  private completeShipmenturl = this.rootPath + '/shipment'
  // search deliveryUrl
  private searchDeliveryUrl = this.rootPath + '/search/deliveryorder'
  // search ReceiptUrl
  private searchReceiptUrl = this.rootPath + '/search/pickup/material'
  /*-----------------------EXCEL DOWNLOAD APIs--------------------------------*/

  private downloadStmt = this.rootPath + '/stock/statement/download/date';
  private downloadStockTransaction = this.rootPath + '/stock/transaction/download';
  private downloadStmtLedger = this.rootPath + '/stock/ledger/download/warehouseAndCustomerAndDate';
  private downloadStmtGRN = this.rootPath + '/goodsReciept/download/customer';
  private downloadStmtDN = this.rootPath + '/delivery/request/download/delivery/customer';

  /*-------------------------END EXCEL------------------------------------------*/

  private GetOrderdetailsAPI = this.rootPath + '/delivery/request/ordernumber';
  private orderDocumentNumberAPI = this.rootPath + '/deliverymaterials/orders/partial';
  private PartialOrderAPI = this.rootPath + '/deliverymaterials/orders/partial/date/start';
  decodedToken: any;
  helper = new JwtHelperService();


  public subject = new BehaviorSubject<any>('');
 
  constructor(private http: HttpClient) { }

  saveData<T>(data: T) {
    this.subject.next(data)
  }

  on<T>(): Observable<T> {
    return this.subject.asObservable()
  }
  /*-------------------------GRN Reports Component------------------------------------------*/

  getGrnDetails(data) {
    return this.http.get(this.grndetails + '?' + 'customerId=' + localStorage.getItem('CusID') + '&warehouseId=' + localStorage.getItem('WarehouseID') +
      '&endDate=' + data.endDate + '&startDate=' + data.startDate
      + '&pageLimit=' + data.pageLimit + '&pageNumber=' + data.pageNumber)
  }

  getreturnGrnDetails(data) {
    return this.http.get(this.returngrndetails + '?' + 'customerId=' + localStorage.getItem('CusID') + '&warehouseId=' + localStorage.getItem('WarehouseID')
      + '&endDate=' + data.endDate + '&startDate=' + data.startDate + '&pageLimit=' + data.pageLimit + '&pageNumber=' + data.pageNumber)
  }

  grnNumberInformation() {
    return this.http.get<any>(this.rootPath + '/transactions/grn' + "/" + localStorage.getItem('grn_id'))
  }

  /*-------------------------DN Reports Component------------------------------------------*/

  getDeliveryNote(data) {
    return this.http.get(this.deliverynotes + '?' + 'customerId=' + localStorage.getItem('CusID') + '&warehouseId=' + localStorage.getItem('WarehouseID') +
      '&endDate=' + data.endDate + '&startDate=' + data.startDate
      + '&pageLimit=' + data.pageLimit + '&pageNumber=' + data.pageNumber + '&channel=' + data.channel)

  }

  DownloadReportDndet(data): Observable<HttpResponse<any>> {
    var url1 = this.rootPath + '/transactions/dn/download' + "?" + '&customerId=' + localStorage.getItem('CusID') + '&warehouseId='
      + localStorage.getItem('WarehouseID') + '&startDate=' + data.startDate + '&endDate=' + data.endDate
    return this.http.get<HttpResponse<any>>(url1, {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    })
  }


  DnNumberInformation() {
    return this.http.get<any>(this.rootPath + '/transactions/dn' + "/" + localStorage.getItem('dn_id'))
  }
  getChannellist() {
    return this.http.get(this.rootPath + '/transactions/dn/channel' + "/" + localStorage.getItem('CusID'))
  }

  /*------------------------ ---------------------------------------------------------------*/



  geTrackingDetails(User) {
    return this.http.post<any>(this.apitracking, User)
  }

  getStoreWithUs(User) {
    return this.http.post<any>(this.StoreWithUsUrl, User)
  }

  getContact(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.http.post<any>(this.contactUrl, User, httpOptions)
  }

  getRequestquote(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.http.post<any>(this.requestquoteUrl, User, httpOptions)
  }

  getReachoutus(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.http.post<any>(this.reachoutusUtl, User, httpOptions)
  }
  getBookingDetails(User) {
    return this.http.post<any>(this.createBookingurl, User)
  }
  getLoginApi(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.http.post<any>(this.LoginUrl, User, httpOptions)
  }
  getLogOutApi() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("session_access_token")
      })
    }
    // console.log("TOKEN", localStorage.getItem("session_access_token"))
    return this.http.post<any>(this.LogOutUrl, {}, httpOptions)
  }
  getSignUpApi(User) {
    return this.http.post<any>(this.SignUpUrl, User)
  }
  getUregisterApi(User) {
    return this.http.post<any>(this.UnregisterUrl, User)
  }

  getUpdateUser(User) {
    return this.http.put(`${this.updateUrl + "/" + localStorage.getItem('ID')}`, User);
  }

  GetOtpGenerate(User) {
    return this.http.post<any>(this.GenerateOTPUrl, User)
  }

  GetOtpResend(User) {
    return this.http.post<any>(this.ResendOTPUrl, User)
  }

  GetOtpVerify(User) {
    return this.http.post<any>(this.VerifyOTPUrl, User)
  }

  getRefreshToken(User) {
    return this.http.post<any>(this.RefreshTokenUrl, User)
  }

  private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
  getNewRefreshToken(): Observable<any> {
    let token = localStorage.getItem('Refresh_token');

    return this.http.post<any>(this.RefreshTokenUrl, { token }).pipe(
      map(result => {
        if (result && result.token) {
          this.loginStatus.next(true);
          localStorage.setItem('loginStatus', '1');
          localStorage.setItem('session_access_token', result.token);
          this.decodedToken = this.helper.decodeToken(localStorage.getItem('session_access_token'));
          localStorage.setItem('CusID', this.decodedToken.customerId)
          localStorage.setItem('ID', this.decodedToken.id)
          localStorage.setItem('U_name', this.decodedToken.name)
          localStorage.setItem('U_email', this.decodedToken.email)
        }

        return <any>result;

      })
    );

  }

  checkLoginStatus(): boolean {

    var loginCookie = localStorage.getItem("loginStatus");

    if (loginCookie == "1") {
      if (localStorage.getItem('session_access_token') != null || localStorage.getItem('session_access_token') != undefined) {
        return true;
      }
    }
    return false;
  }

  logout() {
    // Set Loginstatus to false and delete saved jwt cookie
    this.loginStatus.next(false);
    localStorage.clear()
    console.log("Logged Out Successfully");

  }


  /*---------------------------------------VIEW BOOKINGS-------------------------------------*/
  getViewBookingConfirm(User) {
    return this.http.get<any>(this.ViewBookingUrl + "?token=" + User.token)
    // return this.http.get<any>(this.ViewBookingUrl, User)
  }

  /*---------------------------------------RECEIPT PRE_ALERT-------------------------------------*/
  getReceiptAlert(User) {
    return this.http.post<any>(this.ReceiptUrl, User)
  }
  /*------------------------------------STOCK STATEMENT--------------------------------------*/
  getStockStmt(User) {
    // console.log("USER VALUES",User)
    // return this.http.get<any>(this.StockStmtUrl+"?token="+User.token)
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('session_access_token')
      })
    }
    return this.http.post<any>(this.StockStmtUrl + "/" + (localStorage.getItem('SDate') || localStorage.getItem('DefaultDate')), User, httpOptions)
  }

  SendSOHApi(User) {
    return this.http.post<any>(this.SendingSohUrl, User)
  }

  //-----------------------------------DOWNLOAD REPORT-----------------------------------------//

  DownloadReportAPI(User): Observable<any> {
    let url = this.downloadStmt + "/" + (localStorage.getItem('SDate') || localStorage.getItem('DefaultDate')) + "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" + "customer" + "/" + localStorage.getItem("CusID")

    var body = {
      "userId": localStorage.getItem('ID'),
      "warehouseId": localStorage.getItem('WarehouseID')
    };

    return this.http.post(url, body, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });

  }

  // private saveAsExcelFile(buffer: any, fileName: string): void {
  //   const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //   const EXCEL_EXTENSION = '.xlsx';
  //   const data: Blob = new Blob([buffer], {
  //     type: EXCEL_TYPE
  //   });
  //   FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  // }

  DownloadTransactionReportAPI(User): Observable<HttpResponse<any>> {
    let url = this.downloadStockTransaction

    var body = {
      "userId": User.userId,
      "warehouseId": User.warehouseId,
      "date": User.date
    };

    return this.http.post<HttpResponse<any>>(url, body, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });

  }

  DownloadReportLedgerAPI(User): Observable<HttpResponse<any>> {
    var url = this.downloadStmtLedger
    var body = {
      "customerId": User.customerId,
      "warehouseId": User.warehouseId,
      "productId": User.productId,
      "startDate": User.startDate,
      "endDate": User.endDate
    };
    return this.http.post<HttpResponse<any>>(url, body, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });
  }

  DownloadReportGRN(data): Observable<HttpResponse<any>> {
    var url = this.downloadStmtGRN + "/" + localStorage.getItem("CusID") + "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" + data.startDate + "/" + data.endDate
    return this.http.get<HttpResponse<any>>(url, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    })
  }

  // download report grndetailscomponent

  DownloadReportGRNdet(data): Observable<HttpResponse<any>> {
    var url1 = this.rootPath + '/transactions/grn/download' + "?" + '&customerId=' + localStorage.getItem("CusID") + '&warehouseId='
      + localStorage.getItem('WarehouseID') + '&startDate=' + data.startDate + '&endDate=' + data.endDate
    return this.http.get<HttpResponse<any>>(url1, {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    })
  }


  DownloadReportDN(): Observable<HttpResponse<any>> {
    var url = this.downloadStmtDN + "/" + localStorage.getItem("CusID") + "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" + localStorage.getItem('StartDateExcel') + "/" + localStorage.getItem('EndDateExcel')
    return this.http.get<HttpResponse<any>>(url, {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    })
  }

  getPartialDetails() {
    return this.http.get<any>(this.PartialOrderAPI + "/" + localStorage.getItem('StartDateExcel') + "/" + "end" + "/" + localStorage.getItem('EndDateExcel') + "/" + "customer" + "/" + localStorage.getItem("CusID"))
  }
  //-----------------------------------SELECT MATERIAL-------------------------------------------//
  getSelectMaterial(User) {

    return this.http.post<any>(this.SelectMaterialUrl, User)
  }

  getSelectMaterialProduct(User) {
    return this.http.post<any>(this.SelectMaterialSearchApi, User)
  }
  // ------------------------------SUMMERY COMPONENT----------------------------
  getAddToCart(User) {
    return this.http.post<any>(this.createCartUrl, User)
  }
  // --------------------------------ADD-CART COMPONENT--------------------------------
  getCartDatails() {
    return this.http.get<any>(this.cartDetailsUrl + "/" + localStorage.getItem('ID'))
  }
  getDeleteCartDetails() {
    return this.http.delete<any>(this.CartDeleteUrl + "/" + localStorage.getItem('D_ID'));
  }
  /*------------------------------------STOCK LEDGER---------------------------------------*/
  getLedgerUserId(User) {
    return this.http.post<any>(this.LedgerDataUrl, User)
    // return this.http.get(this.LedgerDataUrl, User)
  }
  getLedgerMaterial(User) {
    return this.http.post<any>(this.LedgerMaterialUrl, User)
  }
  /*---------------------------------------INDIVIDUAL VIEW BOOKINGS-------------------------------------*/
  getIndividualBooking(): Observable<any> {
    return this.http.get<any>(this.IndBookingUrl + "/" + localStorage.getItem('ID'))
    // return this.http.get<any>(this.ViewBookingUrl, User)
  }
  /*--------------------------------------ADDITIONAL SPACE REQUEST--------------------------------*/
  getAdditionalSpace(User) {
    return this.http.post<any>(this.AdditionalSpaceUrl, User)
  }
  /*----------------------------------------DELIVERY ORDER SUMMARY-----------------------------------*/
  getDeliveryOrder(User) {
    return this.http.get<any>(this.DeliveryOrderUrl)
    // return this.http.get<any>(this.supplierAutoSuggestUrl)

  }

  getAutoSuggestDetails() {
    return this.http.get<any>(this.AutoSuggestUrl + "/" + localStorage.getItem('CusID'))
    // return this.http.get<any>(this.supplierAutoSuggestUrl + "/" + localStorage.getItem('CusID'))


  }
  /*------------------------------------------DELIVERY ORDER-------------------------------------*/
  getDeliveryRequest(User) {
    return this.http.post<any>(this.DeliverOrderRequestUrl, User)
  }
  getToken() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('session_access_token');
    return helper.isTokenExpired(token);
    // This condition check Isexpired (true or false)
    // console.log("TOKEN GETTING", token)
    // return token;
  }

  RefreshTokenExpired() {
    const helper = new JwtHelperService();
    const refreshToken = localStorage.getItem('Refresh_token')
    return helper.isTokenExpired(refreshToken);
    // This condition check Isexpired (true or false)
    // console.log("TOKEN GETTING", token)
    // return token;
  }


  // -----------------------------------------------VIEW DASHBOARD------------------------
  getDashboardDetails(User) {
    return this.http.post<any>(this.viewDashboardUrl, User)
  }
  getDashboardByUser(User) {
    return this.http.post<any>(this.dashboardByUserUrl, User)
  }
  getDeliveryDetails() {
    return this.http.get<any>(this.DeliveryDetailUrl + "/" + localStorage.getItem('Delivery_Id'))
  }
  getReceiptDetails() {
    return this.http.get<any>(this.ReceiptTodayUrl + "/" + localStorage.getItem('pending_receipt_id'))

  }
  //-----------------------------------------BUSINESS CATEGORY--------------------------------------//

  getAllBusinessCategory() {
    return this.http.get<any>(this.BusinessCategoryUrl)
  }
  getSubCategory() {
    return this.http.get<any>(this.SubCategoryUrl + "/" + localStorage.getItem('Bus_Cat_id'))
  }
  getUpdateProfileUrl(User) {
    return this.http.put(`${this.UpdateUserProfileUrl + "/" + localStorage.getItem('ID')}`, User);
  }
  //--------------------------------------------PENDING STATUS FOR RECEIPT---------------------------//
  getPendingReceipt(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('session_access_token')
      })
    }
    return this.http.post<any>(this.PendingStatusUrl, User, httpOptions)
  }
  getReadOnlyReceipt() {
    return this.http.get<any>(this.ReadOnlyPendingUrl + "/" + localStorage.getItem('pending_receipt_id'))
  }
  getPendingDelivery() {
    return this.http.get<any>(this.PendingDeliveryUrl + "/" + localStorage.getItem('pending_delivery_id'))
  }

  getorderNumberDetails() {
    return this.http.get<any>(this.orderDocumentNumberAPI + "/" + localStorage.getItem('OrderDocNumber'))
  }
  //--------------------------------------------PENDING STATUS FOR DELIVERY---------------------------//
  getDeliveryStatus(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('session_access_token')
      })
    }
    return this.http.post<any>(this.CompletedStatusUrl, User, httpOptions)
  }
  //--------------------------------------------customer details for filter in completed deliveries---------------------------//

  getDeliveryStatusfilter() {
    return this.http.get<any>(this.viewCustomersUrl + "/" + localStorage.getItem('WarehouseID'))
  }

  BulkcompleteManifest(data) {
    console.log(data)
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });
    return this.http.post(this.bulkCompleteManifestUrl, data, { headers: headers })

  }



   // ---------------------------------------------Dashboard new banners,charts and graphs--------------------------------------------------------

  getInfoDashboard(obj) {
    return this.http.post(this.dashboardBannerUrl, obj)
  }
  getInfoReceptChart(obj) {
    return this.http.post(this.receiptCountUrl, obj)
  }
  getInfoDeliveryChart(obj) {
    return this.http.post(this.deliveryCountUrl, obj)
  }
  getUnfillableOrder(obj) {
    return this.http.post(this.UnfulfillableordersUrl, obj)
  }
  getskuInfo(obj) {
    return this.http.get(this.goodDamageSkuUrl + '/' + 'warehouseId' + '/' + obj.warehouseId + '?' + "customerId=" + obj.cusId)
   }
  getReceiptHistoryChart(obj) {
    return this.http.post(this.receiptHistoryUrl, obj)
  }
  getDeliveryHistoryChart(obj) {
    return this.http.post(this.deliveryHistoryUrl, obj)
  }
  mostOrderPlaced(obj) {
    return this.http.post(this.mostOrderPlacedUrl, obj)

  }
  topSellingProduct(obj) {
    return this.http.get(this.topSellingProductUrl + '/' + 'warehouseId' 
    + '/' + localStorage.getItem('WarehouseID') + '?' + "customerId=" + 
    localStorage.getItem("CusID") + '&endDate=' + obj.endDate + '&startDate=' + obj.startDate)
  }
  allTopSellingProduct(obj){
    return this.http.get(this.topSellingProductUrl + '/' + 'warehouseId' 
    + '/' + localStorage.getItem('WarehouseID') + '?' + "customerId=" + 
    localStorage.getItem("CusID") + '&endDate=' + obj.endDate + '&startDate=' + obj.startDate
      +'&pageLimit='+ obj.pageLimit +  '&pageNumber=' + obj.pageNumber)

    // return this.http.get(this.topSellingProductUrl + '/' + 'warehouseId' + '/'
    //  + localStorage.getItem('WarehouseID') 
    // + '?' +'pageLimit='+ obj.pageLimit +  '&pageNumber=' + obj.pageNumber)
  }

   leastSellingProduct(obj) {
    return this.http.get(this.leastSellingProductUrl + '/' + 'warehouseId' +
     '/' + localStorage.getItem('WarehouseID')  + '?' + "customerId=" + 
    localStorage.getItem("CusID")  + '&endDate=' + obj.endDate + '&startDate=' + obj.startDate)
  }
  allLeastSellingProduct(obj){
    // return this.http.get(this.leastSellingProductUrl + '/' + 'warehouseId' + '/' + localStorage.getItem('WarehouseID') + '?'
    //  +'pageLimit='+ obj.pageLimit +  '&pageNumber=' + obj.pageNumber)
    return this.http.get(this.leastSellingProductUrl + '/' + 'warehouseId' +
     '/' + localStorage.getItem('WarehouseID')  + '?' + "customerId=" + 
    localStorage.getItem("CusID")  + '&endDate=' + obj.endDate + '&startDate=' + obj.startDate
      + '&pageLimit='+ obj.pageLimit +  '&pageNumber=' + obj.pageNumber)


  }
   getLineChart() {
     return this.http.get(this.lineChartUrl)
  }
  // ---------------------------------------------COMPLETED DELIVERY FOR INDIVIDUAL STATUS--------------
  getCompletedIndDelevery() {
    return this.http.get<any>(this.CompleteDeliveryUrl + "/" + localStorage.getItem('complete_delivery_id'))
  }

  getAlldeliverydetails() {
    return this.http.get<any>(this.GetOrderdetailsAPI + "/" + localStorage.getItem('OrderNumber'))
  }

  getViewCompletedDelivery(): Observable<any> {
    return this.http.get<any>(this.viewSelectedDeliverytUrl + "/" + localStorage.getItem('complete_delivery_id'))
  }


  generateInvoiceAndLabel(data) {
    console.log(data)
    console.log(data.customerId)
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });
    return this.http.post(this.generateInvoiceUrl, data, { headers: headers })


  }
  completeShipment(data) {

    return this.http.post(this.completeShipmenturl, data)
  }
  completeMinifest(data) {
    console.log(data)
    // const headers = new HttpHeaders(
    //   {
    //     'Content-Type': 'application/json',
    //     'x-access-token': localStorage.getItem("token")

    //   }
    // );
    return this.http.post(this.completeMenifestUrl, data)
  }
  allocateShipping(data) {
    return this.http.post(this.allocateShipUrl, data)
  }
  createLabel(data) {
    return this.http.post(this.createLabelUrl, data)
  }

  shippingLabelLink(data) {
    return this.http.put(this.shippingLableUrl, data)
  }
  manifestLbelLink(data) {
    return this.http.put(this.manifestLableUrl, data)
  }

  cancelShipment(data) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem("token")

      }
    );
    return this.http.post(this.cancelShipmentUrl, data, { headers: headers })
  }

  //-----------------------------------------CITIES-------------------------------------------//
  getCities() {
    return this.http.get<any>(this.citiesUrl)
  }
  public upload(formData): Observable<any> {
    return this.http.post<any>(this.UploadDocUrl, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  public uploadPo(formData): Observable<any> {
    return this.http.post<any>(this.uploadPoUrl, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  public DeliveryUpload(formData): Observable<any> {
    return this.http.post<any>(this.DeliveryUploadUrl, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  getSkuMaterial(User) {
    return this.http.post<any>(this.AutoComAddMaterialUrl, User)
  }
  getSKUMaster() {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('session_access_token')
      })
    }
    return this.http.get<any>(this.SKUMasterUrl + "/" + localStorage.getItem('CusID') + "/" + localStorage.getItem('PageNo'), httpOptions)
  }

  getMaterialMasterProduct(User) {
    return this.http.post<any>(this.MaterialMasterSearchApi + "/" + localStorage.getItem('CusID') + "/" + localStorage.getItem('Selected_Product'), User)
  }

  getMaterials(User) {
    return this.http.post<any>(this.addMaterialUrl, User)
  }
  cancelReceipt(User) {
    return this.http.put<any>(`${this.cancelReceiptUrl + "/" + localStorage.getItem('pending_receipt_id')}`, User);

  }
  cancelDelivery(User) {
    return this.http.post<any>(this.cancelDeliveryUrl + "/" + localStorage.getItem('pending_delivery_id'), User)
  }
  getWarehouseLoginApi(User) {
    return this.http.post<any>(this.warehouseLogin, User)
  }
  getMonthlyreports(User) {
    return this.http.post<any>(this.monthlyReportsUrl, User)
  }
  getDailyreports(User) {
    return this.http.post<any>(this.dailyReportsUrl, User)
  }
  getPowithMaterial(User) {
    return this.http.post<any>(this.poWithMaterialUrl, User)
  }
  getDeletePo() {
    return this.http.delete<any>(`${this.poDeleteUrl + "/" + localStorage.getItem('PoId')}`);

  }
  getPoSubMaterial(poMasterId) {
    return this.http.get<any>(`${this.poSubMaterialUrl}/${poMasterId}`)
  }
  // getPoSubMaterial(){
  //   return this.http.get<any>(`${this.poSubMaterialUrl}/${localStorage.getItem('poMasterId')}`).toPromise().then(res => <TreeNode[]>res.result.poMaterial);
  // }
  GetSupplierInfo() {
    // return this.http.get<any>(this.SupplierInfoApi + "/" + localStorage.getItem('CusID'))
    return this.http.get<any>(this.supplierAutoSuggestUrl + "/" + localStorage.getItem('CusID'))

  }

  GetProductName(User) {
    return this.http.post<any>(this.ProductInfoApi, User)
  }

  GetAllMaterials(User) {
    return this.http.post<any>(this.ProductbasedMaterialApi, User)
  }

  CountryCode() {
    return this.http.get<any>(this.CountryCodeUrl)
  }
  //-----------------------------------------create shipment-------------------------

  getRate(data) {
    return this.http.post(this.getRateUrl, data)
  }
  createShipment(data) {
    return this.http.post(this.completeShipmenturl, data)
  }
  // search implementation new
  searchDeliveryRequest(obj) {
    return this.http.post(this.searchDeliveryUrl, obj)
  }

  searchReceiptRequest(obj) {
    return this.http.post(this.searchReceiptUrl, obj)

  }
}


