import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import lodash from 'lodash';
import { ApiService } from '../api.service';
import { MustMatch } from '../dash-board/changepassword/passwordvalidator';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  ContactForm: FormGroup;
  submitted = false;

  contactusRequest = { name:'', email:'',mobile:'', companyName:'',message:'',};


  constructor(private formBuilder: FormBuilder, private router: Router, private APiCall :ApiService) { }

  ngOnInit() {
    this.ContactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      company: ['', Validators.required],
      message: ['']
    })
  }

  get f() {
    return this.ContactForm.controls;
  }

  SubmitToChange() {
    this.submitted = true;
    if (this.ContactForm.invalid) {
      return;
    }
    // const dataToBeSent = this.contactusRequest
    // if(lodash.isEqual(dataToBeSent.name,'')) delete dataToBeSent.name
    // if(lodash.isEqual(dataToBeSent.email,'')) delete dataToBeSent.email
    // if(lodash.isEqual(dataToBeSent.mobile,'')) delete dataToBeSent.mobile
    // if(lodash.isEqual(dataToBeSent.organization,'')) delete dataToBeSent.organization
    // if(lodash.isEqual(dataToBeSent.feedbacktext,'')) delete dataToBeSent.feedbacktext
    this.APiCall.getContact(this.contactusRequest)
    .subscribe(
      res => {
        Swal.fire({
          toast: true,
          title: 'Submitted Successfully',
          type: "success",
          showConfirmButton: false,
          timer: 3000
        })
        // //console.log("UPDATED", res)
        err => {
          Swal.fire({
            toast: true,
            title: err,
            type: "error",
            showConfirmButton: false,
            timer: 3000
          })
          //console.log("FOUND ERROR", err)
        }
        this.router.navigate([''])
      }
    )
    // //console.log("Changes Happening")
  }

}
