import { Component, ChangeDetectorRef, OnInit, AfterViewChecked } from '@angular/core';
import { animate, style, transition, trigger } from "@angular/animations";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-omni-service',
  templateUrl: './omni-service.component.html',
  styleUrls: ['./omni-service.component.css'],
  animations: [
    trigger('fade', [
      transition('void=>*', [
        style({ opacity: "0" }),
        animate(2000)
      ])
    ])
  ]
})
export class OmniServiceComponent implements OnInit {
  public OmniValue = '';

  mobileMedia: any = window.matchMedia("(max-width:1000px)")
  constructor(private getData: ApiService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    AOS.init({
      duration: 2000,
    })

    this.getData.on<string>().subscribe(
      data => {
        this.OmniValue = data
      }
    )

    if (this.mobileMedia.matches) {
      if (this.OmniValue == 'warehouse_manage') {
        window.scrollTo({ top: 700, behavior: 'smooth' });
        window.scrollTo(0, 700)
        document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
        this.OmniValue = ''
      }
      else if (this.OmniValue == 'data_analy') {
        window.scrollTo({ top: 1400, behavior: 'smooth' });
        window.scrollTo(0, 1400)
        document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
        this.OmniValue = ''
      }
      else if (this.OmniValue == 'api_int') {
        window.scrollTo({ top: 1900, behavior: 'smooth' });
        window.scrollTo(0, 1900)
        document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
        this.OmniValue = ''
      }
      else if (this.OmniValue == 'omni_channel') {
        window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.OmniValue = ''
      }
    } else {
      setTimeout(() => {
        if (this.OmniValue == 'warehouse_manage') {
          window.scrollTo({ top: 500, behavior: 'smooth' });
          window.scrollTo(0, 500)
          document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
          this.OmniValue = ''
        }

        else if (this.OmniValue == 'data_analy') {
          window.scrollTo({ top: 1000, behavior: 'smooth' });
          window.scrollTo(0, 1000)
          document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
          this.OmniValue = ''
        }

        else if (this.OmniValue == 'api_int') {
          window.scrollTo({ top: 1500, behavior: 'smooth' });
          window.scrollTo(0, 1500)
          document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
          this.OmniValue = ''
        }

        else if (this.OmniValue == 'omni_channel') {
          window.scrollTo(0, 0)
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.OmniValue = ''
        }
      }, 100)
    }



  }

  ngDoCheck() {
    this.cdRef.detectChanges();
    if (this.mobileMedia.matches) {
      if (this.OmniValue == 'warehouse_manage') {
        window.scrollTo({ top: 700, behavior: 'smooth' });
        window.scrollTo(0, 700)
        document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
        this.OmniValue = ''
      }

      else if (this.OmniValue == 'data_analy') {
        window.scrollTo(0, 1400)
        window.scrollTo({ top: 1400, behavior: 'smooth' });
        document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
        this.OmniValue = ''
      }

      else if (this.OmniValue == 'api_int') {
        window.scrollTo(0, 1900)
        window.scrollTo({ top: 1900, behavior: 'smooth' });
        document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
        this.OmniValue = ''
      }

      else if (this.OmniValue == 'omni_channel') {
        window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.OmniValue = ''
      }
    }
    else {
      setTimeout(() => {
        if (this.OmniValue == 'warehouse_manage') {
          window.scrollTo({ top: 500, behavior: 'smooth' });
          window.scrollTo(0, 500)
          document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
          this.OmniValue = ''
        }

        else if (this.OmniValue == 'data_analy') {
          window.scrollTo({ top: 1000, behavior: 'smooth' });
          window.scrollTo(0, 1000)
          document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
          this.OmniValue = ''
        }

        else if (this.OmniValue == 'api_int') {
          window.scrollTo({ top: 1500, behavior: 'smooth' });
          window.scrollTo(0, 1500)
          document.getElementById(this.OmniValue).scrollIntoView({ behavior: 'smooth' })
          this.OmniValue = ''
        }

        else if (this.OmniValue == 'omni_channel') {
          window.scrollTo(0, 0)
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.OmniValue = ''
        }
      }, 100)
    }
  }
}
