import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { } from 'googlemaps';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';
import { trigger, transition, style, animate } from '@angular/animations';
import Swiper from 'swiper';
import lodash from 'lodash';
import { Router } from '@angular/router';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

interface Month {
  value: string;
  viewValue: string;
}
interface City {
  value: string;
  viewValue: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('slidebackward', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('100ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      // transition(':leave', [
      //   animate('800ms ease-in', style({transform: 'translateX(100%)'}))
      // ])
    ]),
    trigger('slideforward', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('100ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      // transition(':leave', [
      //   animate('800ms ease-out', style({transform: 'translateX(-100%)'}))
      // ])
    ])
  ]
})

export class HomeComponent implements OnInit {
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
  address: string;
  latitude: number;
  longitude: number;
  pickUpPoint: string;
  bookingSubmitted = false
  zoom: number;
  private geoCoder;
  contactOurs: boolean;
  bookingFormGroup: FormGroup;

  months: Month[] = [
    { value: '15', viewValue: '15 days' },
    { value: '30', viewValue: '1 month' },
    { value: '60', viewValue: '2 months' },
    { value: '90', viewValue: '3 months' },
    { value: '180', viewValue: '6 months' },
    { value: '200', viewValue: '> 6 months' }
  ];
  cities: City[] = [
    { value: '1', viewValue: 'Bangalore' },
    { value: '2', viewValue: 'Chennai' }
    // { value: 'Hyderabad', viewValue: 'Hyderabad' },
  ]

  homescreenRequired = {
    fullname: "",
    email: "",
    mobileNo: "",
    areaReq: "",
    storageDuration: "",
    possibleStartDate: "",
    organizationName: "",
    cityId: "",
    requestSource: "home"
  };
  siginToken: string;
  NextTab = false;
  minDate = new Date();

  public show: boolean = true;

  public type: string = 'component';

  public disabled: boolean = false;

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 3,
    speed: 1000,
    loop: true,
    keyboard: true,
    centeredSlides: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: true,
    breakpoints:{
      900:{
           slidesPerView: 1, 
          }
        },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  };

  constructor(private ngZone: NgZone, private mapsAPILoader: MapsAPILoader, private formBuilder: FormBuilder, private Apicall: ApiService, private datePipe: DatePipe, @Inject(DOCUMENT) private _document: Document, private router: Router, private sendFile: ApiService) { }

  ngOnInit() {
    AOS.init({
      duration: 2000,
    })

    this.createForm()
    //     this.mapsAPILoader.load().then(() => {
    //   this.setCurrentPosition();
    //   this.geoCoder = new google.maps.Geocoder;
    //   var bangaloreBounds = new google.maps.LatLngBounds(
    //     new google.maps.LatLng(12.864162, 77.438610),
    //     new google.maps.LatLng(13.139807, 77.711895));
    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
    //     bounds: bangaloreBounds,
    //     strictBounds: true,
    //     types: ["establishment"],
    //     componentRestrictions: { 'country': 'in' }
    //   });

    //   autocomplete.addListener('place_changed', () => {
    //     this.ngZone.run(() => {
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       this.getAddress(this.latitude, this.longitude);
    //       this.zoom = 12;
    //       this.pickUpPoint = this.latitude + ',' + this.longitude;
    //       // //console.log("pickup", this.pickUpPoint)
    //     });
    //   });
    // });
    var swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      // spaceBetween: 30,
      loop: true,
      speed: 1000,
      direction: 'horizontal',
      keyboard: true,
      mousewheel: true,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        }
      },
      centeredSlides: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          // //console.log('came here 1');
          this.address = results[0].formatted_address;
          // this.bookingData.location = this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }
  setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 2;
        this.getAddress(this.latitude, this.longitude);
      })
    }
  }


  createForm() {
    this.bookingFormGroup = this.formBuilder.group({
      areaReq: ['', Validators.required],
      storageDuration: ['', Validators.required],
      possibleStartDate: ['', Validators.required],
      city: ['', Validators.required],
      fullname: [''],
      mobileNo: ['', Validators.required],
      organization: [''],
    });
  }

  public checkError = (controlName: any, errorName: any) => {
    return this.bookingFormGroup.controls[controlName].hasError(errorName);
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  NextTabs() {
    this.NextTab = true
  }

  BackwardTab() {
    this.NextTab = false
  }

  getBookingDatas() {
    if (this.bookingFormGroup.invalid) {
      return;
    }
    // localStorage.setItem('AOR', this.homescreenRequired.areaReq)
    // localStorage.setItem('DT', this.homescreenRequired.possibleStartDate)
    // localStorage.setItem('DUE', this.homescreenRequired.storageDuration)
    // localStorage.setItem('CITY', this.homescreenRequired.cityId)
    //  localStorage.setItem('MOB', this.homescreenRequired.mobileNo)
    // this.homescreenRequired.possibleStartDate = this.datePipe.transform(this.date.value, 'yyyy-MM-dd')
    this.homescreenRequired.possibleStartDate = this.datePipe.transform(this.homescreenRequired.possibleStartDate, 'yyyy-MM-dd')
    // this.siginToken = localStorage.getItem('session_access_token')
    // this.homescreenRequired.mobileNo = localStorage.getItem('mobile_no')
    // this.homescreenRequired.password = localStorage.getItem('pwd')

    const dataToBeSent = this.homescreenRequired
    if (lodash.isEqual(dataToBeSent.fullname, '')) delete dataToBeSent.fullname
    if (lodash.isEqual(dataToBeSent.email, '')) delete dataToBeSent.email
    if (lodash.isEqual(dataToBeSent.organizationName, '')) delete dataToBeSent.organizationName

    console.log("Token getting here", this.homescreenRequired)
    this.Apicall.getStoreWithUs(this.homescreenRequired)
      .subscribe(res => {
        if (res.status == true) {

          Swal.fire({
            title: "<h6 style='color: #27ADE2;'>Thank you for posting your requirement. Our Sales team shall get in touch with you soon</h6>",
            showConfirmButton: false,
            timer: 7000,
            imageUrl: 'assets/images/Confirmbooking.png',
          })

          setTimeout(() => {
            this.formDirective.resetForm();
            this._document.defaultView.location.reload();
            this.NextTab = false
          }, 4000)
        }
      })
    err => {
      console.log("Error Spotted")
    }

  }

  SendingmaximizeValue() {
   let maximizeValue = document.getElementById('maximizeValue').id
    this.sendFile.saveData<string>(maximizeValue.toString());
    this.router.navigate(['ond'])
  }

  SendingminizeValue(){
   let minizeValue = document.getElementById('minizeValue').id
   this.sendFile.saveData<string>(minizeValue.toString());
   this.router.navigate(['ond'])
  }

  SendingDelightValue(){
    let DelightValue = document.getElementById('DelightValue').id
    this.sendFile.saveData<string>(DelightValue.toString());
    this.router.navigate(['ond'])
  }

  SendingStockValue(){
    let StockValue = document.getElementById('StockValue').id
    this.sendFile.saveData<string>(StockValue.toString());
    this.router.navigate(['ond'])
  }

  SendingPocketValue(){
    let PocketValue = document.getElementById('PocketValue').id
    this.sendFile.saveData<string>(PocketValue.toString());
    this.router.navigate(['ond'])
  }

  SendingLeanValue(){
    let LeanValue = document.getElementById('LeanValue').id
    this.sendFile.saveData<string>(LeanValue.toString());
    this.router.navigate(['ond'])
  }

    toHowitswork(){
    // document.getElementById('howitsworks').scrollIntoView({behavior:'smooth'})
    this.router.navigate(['howitsworks'])
  }
}
