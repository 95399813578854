import { Component, ViewEncapsulation, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { animate, style, transition, trigger } from "@angular/animations";
import { collapseAnimation } from './animation';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ApiService } from './api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void=>*', [
        style({ opacity: "0" }),
        animate(2000)
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  ngOnInit(){

  }
  constructor(public router: Router){}
 }